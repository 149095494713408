import CV9CoverImage from "../assets/project-images/CV9/cv9-cover.png";
import CV9FrontImage from "../assets/project-images/CV9/cv9-front.jpg";


import MHCoverImage from "../assets/project-images/MH Appliances/mh-cover.jpg";
import MHFrontImage from "../assets/project-images/MH Appliances/mh-front.jpg";


import EcommerceCover from "../assets/project-images/ecommerce/ecommerce-cover.jpg";
import EcommerceFrontImage from "../assets/project-images/ecommerce/ecommerce-front.jpg";


import PortfolioTCover from "../assets/project-images/PortfolioT/PortfolioT-cover.jpg";
import PortfolioTFrontImage from "../assets/project-images/PortfolioT/PortfolioT-front.jpg";

import RockpaveCover from "../assets/project-images/Rockpave Civil/rockpave-cover.jpg";
import RockpaveFrontImage from "../assets/project-images/Rockpave Civil/rockpave-front.jpg";

const projectsConfig = [
  {
    title: "MH Appliances",
    type: "Ecommerce Website",
    description: "Single handedly created a multi-page site for a client under my company, Moetion. Utilised Wordpress, along with multiple uses of custom CSS and Javascript, to effectively and professionally deliver a custom website for the general appliance company, MH Appliances.",
    liveLink: "https://kaizenapparelsourcing.com/",
    coverImage: MHCoverImage,
    topImage: MHFrontImage,
  },
  {
    title: "Rockpave Civil",
    type: "Website",
    description: "Single handedly created a multi-page site for a client under All In IT Solutions. Utilised Wordpress, along with multiple uses of custom CSS and Javascript, to effectively deliver a custom website for one of the leading civil construction / paving companies, Rockpave Civil.",
    liveLink: "https://rockpavecivil.com.au",
    coverImage: RockpaveCover,
    topImage: RockpaveFrontImage,
  },
  {
    title: "CloudV9",
    type: "Product Design + Website",
    description: "Created a suitable logo and minimalistic, professional product design for a client within the bouldering industry. Website is currently under development...",
    liveLink: "https://cloudv9.com.au/",
    coverImage: CV9CoverImage,
    topImage: CV9FrontImage,
  },
  {
    title: "Portfolio Template",
    type: "Portfolio Website",
    description:
      "A nice, sleek and responsive profile template design. This template features multiple fun and wacky animations, and an easy guide to how you can implement this template for your own profile!",
    liveLink: "https://moe-zart.github.io/profile-template/",
    coverImage: PortfolioTCover,
    topImage: PortfolioTFrontImage,
  },
  {
    title: "React Ecommerce",
    type: "Ecommerce Website",
    description: "Browse for the hottest shoes on the market with Shoegame. I am abit of a hypebeast myself and I am always updated with the latest shoes. This project showcases a dramatic improvement in my frontend development skills, as I am able to create a modern and responsive design, while also displaying my passion for shoes.",
    liveLink: "/shoegame",
    coverImage: EcommerceCover,
    topImage: EcommerceFrontImage,
  },
];

export default projectsConfig;
