export const experienceConfig = [
  {
    name: "Moetion Designs (Digital Agency)",
    title: "Founder / Software Engineer",
    description: [
      "Engineered scalable, responsive, multi-platform and SEO friendly websites catered to various industries for multiple clients.",
      "Using HTML5, CSS3 + BEM, JavaScript ES6+, and React to engineer frontend codebases using industry best practises",
      "Communicated business requirements with stakeholders and delivered high quality solutions.",
      "Connected to backend APIs through CRUD HTTP requests.",
    ],
    date: "Feb 2022 - Current",
  },
  {
    name: "All In IT Solutions",
    title: "Web Developer / Designer",
    description: [
      "Applied graphic design principles for digital mock-ups, banners, products and web elements.",
      "Implemented well established communication techniques with clients to hand out desired requests.",
      "Transformed UI/UX wireframes into responsive HTML and CSS (Sass/BEM) and received positive responses from clients",
    ],
    date: "Aug 2022 - Current",
  },
];
