export const technicalSkillsConfig = [
  "JavaScript",
  "React",
  "Html",
  "CSS",
  "Photoshop",
  "Wordpress",
  "Illustrator",
  "Visual Studio",
  "Git",
];
