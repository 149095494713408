import React from 'react';
import buoy from '../assets/Buoy.svg'
const WavesAnimate = () => {
    return (
        <section className="waves_section">
            <div className="waves">
			<div className="wave" id="wave1"></div>
			<div className="wave" id="wave2"></div>
			<div className="wave" id="wave3"></div>
			<div className="wave" id="wave4"></div>
			<img className='buoy' src={buoy}></img> 
			<div className="wave_bottom"> </div>
		</div>
        </section>
    );
}

export default WavesAnimate;
